import React from 'react'
import { Row, Col, Card, Nav, Container } from "react-bootstrap";

const QuickLinks = () => {
  return (
    <>
        <Container id="quicklink" className='my-2'>
          <Row >
            <Col xs={12} md={7} lg={8}>
              <Card
                varient="primary"
                className="mt-2"
                style={{ height: "200px" }}
              >
                <div style={{ width: "100%" }}>
                  <iframe
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      height: "200px",
                      frameborder: "0",
                      style: "border:0",
                    }}
                    src="https://maps.google.com/maps?q=awal%20center&t=k&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                </div>
              </Card>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <Card
                varient="primary"
                className="mt-2"
                style={{ height: "200px" }}
              >
                <Row>
                  <Col xs={6} md={6}>
                    <h6
                      className="font-weight-bold text-center mb-4 my-4"
                      style={{ color: "#7ecc10" }}
                    >
                      Services
                    </h6>
                    <Nav className="flex-column text-center">
                      <Nav.Link style={{ color: "white" }} href="#pricing">
                        Massage
                      </Nav.Link>
                      <Nav.Link style={{ color: "white" }} href="#saloon">
                        Saloon
                      </Nav.Link>
                      <Nav.Link style={{ color: "white" }} href="#saloon">
                        Waxing
                      </Nav.Link>
                    </Nav>
                  </Col>
                  <Col xs={6} md={6}>
                    <h6
                      className="font-weight-bold text-center mb-4 my-4"
                      style={{ color: "#7ecc10" }}
                    >
                      Quick Link
                    </h6>
                    <Nav className="flex-column text-center">
                      <Nav.Link style={{ color: "white" }} href="#contact">
                        Contact
                      </Nav.Link>
                      <Nav.Link style={{ color: "white" }} href="#packages">
                        Packages
                      </Nav.Link>
                      <Nav.Link style={{ color: "white" }} href="#services">
                        Services
                      </Nav.Link>
                    </Nav>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
    </>
  );
}

export default QuickLinks