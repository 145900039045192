import React from 'react'
import { Container, Card, Row, Col } from "react-bootstrap";
import facial from "../img/facial.png";
import massage from "../img/massage.png";
import aroma from "../img/aroma.png";
import waxing from "../img/waxing.png";
import spas from "../img/spas.png";
import saloon from "../img/saloon.png";


const Services = () => {
  return (
    <>
      <Container id='services'>
        <h4 className="py-2 pt-4" style={{ color: "#7ecc10" }}>
          What We Offer
        </h4>
        <Row className="d-flex justify-content-center">
          <Col xs={6} md={4} lg={2}>
            <Card className="mb-3 mt-2">
              <Card.Img src={facial} />
              <Card.Body>
                <Card.Title>Facial </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card className="mb-3 mt-2">
              <Card.Img src={massage} />
              <Card.Body>
                <Card.Title>Massage </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card className="mb-3 mt-2">
              <Card.Img src={aroma} />
              <Card.Body>
                <Card.Title>Aroma</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card className="mb-3 mt-2">
              <Card.Img src={waxing} />
              <Card.Body>
                <Card.Title>Waxing </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card className="mb-3 mt-2">
              <Card.Img src={spas} />
              <Card.Body>
                <Card.Title>Spa </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card className="mb-3 mt-2">
              <Card.Img src={saloon} />
              <Card.Body>
                <Card.Title>Saloon</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Services