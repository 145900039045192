import React from 'react'
import { Container, Card, Row, Col } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import saloon from "../img/saloon.png";
import spa from "../img/spa.png";

const SaloonSpa = () => {
  return (
    <>
      <Container id="saloon">
        <Row className="d-flex justify-content-center pt-5">
          <Col xs={12} md={6} lg={6}>
            <h4 className="py-2 pt-5" style={{ color: "#7ecc10" }}>
              Saloon Services
            </h4>
            <Card className="mb-3 mt-2">
              <Card.Img src={saloon} />
              <Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroup.Item
                    style={{ color: "#7ecc10", fontWeight: "bold" }}
                  >
                    Hair Style
                  </ListGroup.Item>
                  <ListGroup.Item>Hair Cut - 300/- +vat</ListGroup.Item>
                  <ListGroup.Item>Hair Style- 500/- +vat</ListGroup.Item>
                  <ListGroup.Item>Gel Shave - 300/- +vat</ListGroup.Item>

                  <ListGroup.Item
                    style={{ color: "#7ecc10", fontWeight: "bold" }}
                  >
                    Hair Color
                  </ListGroup.Item>
                  <ListGroup.Item>
                    High Speed Hair Color - 850/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Garnier Hair Color - 750/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Begin Hair Color - 1050/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Loreal Hair Color - 1250/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Brown Hair Color - 1500/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>Hair Strid - 3000/- +vat</ListGroup.Item>
                  <ListGroup.Item>Hair Rebonding - 5000/- +vat</ListGroup.Item>
                  <ListGroup.Item
                    style={{ color: "#7ecc10", fontWeight: "bold" }}
                  >
                    Hair Treatment
                  </ListGroup.Item>
                  <ListGroup.Item>Hair Wash - 700/- +vat</ListGroup.Item>
                  <ListGroup.Item>
                    Oil Hair Treatment - 500/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Hair Spa Treatment - 1500/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Aloe Vera Hair Treatment - 1500/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ color: "#7ecc10", fontWeight: "bold" }}
                  >
                    Skin Care
                  </ListGroup.Item>
                  <ListGroup.Item>Normal Face Wash - 500/- +vat</ListGroup.Item>
                  <ListGroup.Item>Scarf Face Wash - 700/- +vat</ListGroup.Item>
                  <ListGroup.Item>Chondon Facial - 1200/- +vat</ListGroup.Item>
                  <ListGroup.Item>Shahnaz Facial - 2000/- +vat</ListGroup.Item>
                  <ListGroup.Item>Gold Facial - 2500/- +vat</ListGroup.Item>
                  <ListGroup.Item>
                    Organic Special Facial - 3000/- +vat
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <h4 className="py-2 pt-5" style={{ color: "#7ecc10" }}>
              Spa Waxing & Massage
            </h4>
            <Card className="mb-3 mt-2">
              <Card.Img src={spa} />
              <Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroup.Item
                    style={{ color: "#7ecc10", fontWeight: "bold" }}
                  >
                    Manicure & Pedicure
                  </ListGroup.Item>
                  <ListGroup.Item>Manicure - 1000/- +vat</ListGroup.Item>
                  <ListGroup.Item>Pedicure - 1500/- +vat</ListGroup.Item>
                  <ListGroup.Item>
                    Special Manicure & Pedicure - 2,500/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ color: "#7ecc10", fontWeight: "bold" }}
                  >
                    Spa Waxing
                  </ListGroup.Item>
                  <ListGroup.Item>Full Arm Wax - 600/- +vat</ListGroup.Item>
                  <ListGroup.Item>
                    Full Underarms Wax - 400/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>Bikini Line Wax - 400/- +vat</ListGroup.Item>
                  <ListGroup.Item>Half Leg Wax - 400/- +vat</ListGroup.Item>
                  <ListGroup.Item>
                    Full Bikini Line Wax - 1,000/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>Full Leg Wax - 1,000/- +vat</ListGroup.Item>
                  <ListGroup.Item>
                    Lip/ Chin/ Brow Wax (each) - 200/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ color: "#7ecc10", fontWeight: "bold" }}
                  >
                    Massage
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Oil Massage (15 Minutes) - 300/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Oil Massage (30 Minutes) - 1000/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Full Body Massage (60 Minutes) - 3500/- +vat
                  </ListGroup.Item>
                  <ListGroup.Item>Foot Massage - 2000/- +vat</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <Card className="mb-3 mt-2">
              <Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroup.Item
                    style={{ color: "#7ecc10", fontWeight: "bold" }}
                  >
                    Full Package
                  </ListGroup.Item>
                  <ListGroup.Item>1. Hair Style</ListGroup.Item>
                  <ListGroup.Item>2.Hair Color</ListGroup.Item>
                  <ListGroup.Item>3.Facial</ListGroup.Item>
                  <ListGroup.Item>4.Massage</ListGroup.Item>
                  <Card.Text as="h5" className="pt-2">
                    BDT 5,500/- +vat
                  </Card.Text>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SaloonSpa