import React,{useEffect, useState} from 'react'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  Carousel,
  Container,
  Row,
  Col,
  Card,
  CardGroup,

  Button, Nav
} from "react-bootstrap";
import d1 from '../img/d1.png'
import d2 from "../img/d2.png";
import d3 from "../img/d3.png";
import m1 from "../img/m1.png";
import m2 from "../img/m2.png";
import m3 from "../img/m3.png";

const Navbar = () => {

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);

  return (
    <>
      {isMobile ? (
            <Carousel indicators="">
              <Carousel.Item>
                <Card>
                  <Card.Img cascade className="img-fluid" src={m1} />
                </Card>
                <Carousel.Caption>
                  <h3 style={{ fontWeight: "bold" }}>
                    Welcome to Organic Spa & Saloon!
                  </h3>
                  <Button variant="success" style={{ fontWeight: "bold" }}>
                    <Nav.Link style={{ color: "white" }} href="#services">
                      Get Started
                    </Nav.Link>
                  </Button>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <Card>
                  <Card.Img cascade className="img-fluid" src={m2} />
                </Card>
                <Carousel.Caption>
                  <h3 style={{ fontWeight: "bold" }}>
                    Relax Yourself With Our Spa & Massage Services!
                  </h3>
                  <Button variant="success" style={{ fontWeight: "bold" }}>
                    <Nav.Link style={{ color: "white" }} href="#services">
                      Get Started
                    </Nav.Link>
                  </Button>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <Card>
                  <Card.Img cascade className="img-fluid" src={m3} />
                </Card>
                <Carousel.Caption>
                  <h3 style={{ fontWeight: "bold" }}>
                    Create a New Style With Our Saloon Service!
                  </h3>
                  <Button variant="success" style={{ fontWeight: "bold" }}>
                    <Nav.Link style={{ color: "white" }} href="#saloon">
                      Get Started
                    </Nav.Link>
                  </Button>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
      ) : (
        <Container>
              <Carousel indicators="">
                <Carousel.Item>
                  <Card>
                    <Card.Img cascade className="img-fluid" src={d1} />
                  </Card>
                  <Carousel.Caption>
                    <h3 style={{ fontWeight: "bold" }}>
                      Welcome to Organic Spa & Saloon!
                    </h3>
                    <Button variant="success" style={{ fontWeight: "bold" }}>
                      <Nav.Link style={{ color: "white" }} href="#services">
                        Get Started
                      </Nav.Link>
                    </Button>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                  <Card>
                    <Card.Img cascade className="img-fluid" src={d3} />
                  </Card>
                  <Carousel.Caption>
                    <h3 style={{ fontWeight: "bold" }}>
                      Relax Yourself With Our Spa & Massage Services!
                    </h3>
                    <Button variant="success" style={{ fontWeight: "bold" }}>
                      <Nav.Link style={{ color: "white" }} href="#services">
                        Get Started
                      </Nav.Link>
                    </Button>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                  <Card>
                    <Card.Img cascade className="img-fluid" src={d2} />
                  </Card>
                  <Carousel.Caption>
                    <h3 style={{ fontWeight: "bold" }}>
                      Create a New Style With Our Saloon Service!
                    </h3>
                    <Button variant="success" style={{ fontWeight: "bold" }}>
                      <Nav.Link style={{ color: "white" }} href="#saloon">
                        Get Started
                      </Nav.Link>
                    </Button>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
        </Container>
      )}
    </>
  );
}

export default Navbar