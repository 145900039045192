import React from "react";
import {Container,Row, Col} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
const Footer = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Col>
            <Row
              className="d-flex justify-content-center"
              style={{ color: "#7ecc10" }}
            >
              Copyright &copy;Organic Spa & Saloon
            </Row>
            <Row
              className="d-flex justify-content-center"
            >
              <p>Designed & Developed by
                <a target="blank" href="https://www.tgitbangladesh.com" style={{textDecoration:'none', fontWeight:'bold'}}> TGIT</a>
              </p>
            </Row>
          </Col>
        </Container>
      </Navbar>
    </>
  );
};

export default Footer;
