import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import {
  FaPhoneAlt,
  FaLocationArrow,
  FaBusinessTime,
  FaMailBulk,
} from "react-icons/fa";

import {
  GiCandlestickPhone
} from "react-icons/gi";



const Contact = () => {
  return (
    <Container className="mt-5" id="contact">
      <Card.Header as="h4" className="my-3" style={{ color: "white" }}>
        Contact Us
      </Card.Header>
      <Row className="d-flex justify-content-center">
        <Col xs={12} md={4} lg={4}>
          <Card className="mb-3 mt-2">
            <Card.Header style={{ color: "#7ecc10" }}>
              <FaPhoneAlt size={50} />
            </Card.Header>
            <Card.Text as="h5" className="pt-2">
              Phone
            </Card.Text>
            <Card.Body>
              <Card.Text as="h6">01718-959279 || 01707-009734</Card.Text>
              <Card.Text as="h6">01707-009735 || 01707-009736</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <Card className="mb-3 mt-2">
            <Card.Header style={{ color: "#7ecc10" }}>
              <GiCandlestickPhone size={50} />
            </Card.Header>
            <Card.Text as="h5" className="pt-2">
              Telephone
            </Card.Text>
            <Card.Body>
              <Card.Text as="h6">+88 02-48810717</Card.Text>
              <Card.Text as="h6"><br></br></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <Card className="mb-3 mt-2">
            <Card.Header style={{ color: "#7ecc10" }}>
              <FaMailBulk size={50} />
            </Card.Header>
            <Card.Text as="h5" className="pt-2">
              Email
            </Card.Text>
            <Card.Body>
              <Card.Text as="h6">organicgroup775@gmail.com</Card.Text>
              <Card.Text as="h6">organicgroup776@gmail.com</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <Card className="mb-3 mt-2">
            <Card.Header style={{ color: "#7ecc10" }}>
              <FaLocationArrow size={50} />
            </Card.Header>
            <Card.Text as="h5" className="pt-2">
              Location
            </Card.Text>
            <Card.Body>
              <Card.Text as="h6">8th floor (Lift: 7), 34 Awal Center, Kemal</Card.Text>
              <Card.Text as="h6">Ataturk Avenue, Banani, Dhaka-1213</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card className="mb-3 mt-2">
            <Card.Header style={{ color: "#7ecc10" }}>
              <FaBusinessTime size={50} />
            </Card.Header>
            <Card.Text as="h5" className="pt-2">
              Office Hour
            </Card.Text>
            <Card.Body>
              <Card.Text as="h6">Saturday-Thursday</Card.Text>
              <Card.Text as="h6">10.00 AM-09.00 PM</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
