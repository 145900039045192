import React from 'react'
import { Container,Card,Row,Col } from 'react-bootstrap'
import GoldMask from '../img/GoldMask.png'
import Lifting from "../img/Lifting.png";
import RoseMask from "../img/RoseMask.png";
import BeautyFacial from "../img/BeautyFacial.png";
import MaskFacial from "../img/MaskFacial.png";
import SteamBath from "../img/SteamBath.png";

const PackageGallery = () => {
  return (
    <>
      <Container id='pricing'>
        <h4 className="py-2 pt-5" style={{ color: "#7ecc10" }}>
          Package Gallery
        </h4>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-3 mt-2" style={{ height: "570px" }}>
              <Card.Img src={GoldMask} />
              <Card.Header as="h6" style={{ color: "#7ecc10" }}>
                Gold Mask Facial 60 Minutes
              </Card.Header>
              <Card.Header className="pb-5" style={{ textAlign: "justify" }}>
                With our Gold Collagen Mask you will enjoy a luxurious feeling
                of relaxtion Gold mask is proven and tested to moisturize and
                hydrate the skin at a professional level It rebuilds collagen
                giving vour skin a more firm and you will look and feel years
                younger.
              </Card.Header>
              <Card.Body>
                <Card.Title>BDT 4,500/- +vat </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-3 mt-2" style={{ height: "570px" }}>
              <Card.Img src={Lifting} />
              <Card.Header as="h6" style={{ color: "#7ecc10" }}>
                Lifting Facial 60 Minutes
              </Card.Header>
              <Card.Header style={{ textAlign: "justify" }}>
                Non-surgical wrinkle removal effectively removes your wrinkles
                with our State. of the art Micro Current wave treatment which
                helps lift both facial and neck muscles. Meanwhile the treat-
                ment also promotes optimum Qxygen absorption and enhances
                natural collagen to accelerate new cell grewth, you will be left
                feeling totaliv rejuvenated with a more radiant and sportless
                conplexion.
              </Card.Header>
              <Card.Body>
                <Card.Title>BDT 6,000/- +vat </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-3 mt-2" style={{ height: "570px" }}>
              <Card.Img src={RoseMask} />
              <Card.Header as="h6" style={{ color: "#7ecc10" }}>
                Rose Mask Facial 40 Minutes
              </Card.Header>
              <Card.Header className="pb-5" style={{ textAlign: "justify" }}>
                Ideal in returning moisture to your skin. It also Provides a
                lustrous glow to your skin Ideal for removing or diminishing age
                lines and dry skin.
              </Card.Header>
              <Card.Body>
                <Card.Title>BDT 3,800/- +vat </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-3 mt-2" style={{ height: "570px" }}>
              <Card.Img src={BeautyFacial} />
              <Card.Header as="h6" style={{ color: "#7ecc10" }}>
                Beauty Facial 50 Minutes
              </Card.Header>
              <Card.Header style={{ textAlign: "justify" }}>
                Organic Spa offers a wide selection of special Beauty and health
                treatment. The delightful experience encompasses all of the
                sence, aroma & spirit of holistic treatment. Our experienced
                therapist will perform a detailed consultation before your
                treatment which best suits your skin type Green.
              </Card.Header>
              <Card.Body>
                <Card.Title>BDT 3,800/- +vat </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-3 mt-2" style={{ height: "570px" }}>
              <Card.Img src={MaskFacial} />
              <Card.Header as="h6" style={{ color: "#7ecc10" }}>
                Mask Facial 60 Minutes
              </Card.Header>
              <Card.Header className="pb-5" style={{ textAlign: "justify" }}>
                A vitamin and mineral enriched seaweed extracts treatment that
                heals Skin ne, prevents acne from leaving marks, cures damage
                and restores the softness and elasticity of skin for a radiant
                complexion Green.
              </Card.Header>
              <Card.Body>
                <Card.Title>BDT 3,800/- +vat </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-3 mt-2" style={{ height: "570px" }}>
              <Card.Img src={SteamBath} />
              <Card.Header as="h6" style={{ color: "#7ecc10" }}>
                Steam Bath 30 Minutes
              </Card.Header>
              <Card.Header style={{ textAlign: "justify" }}>
                Steam rooms are enjoyable and beneficial to both mental and
                physical health. You will get some of the great health benefits
                of using Steam bath such as relief stress and tension, aid in
                healing injuries, benefit the skin, valuable to the lungs and
                boost overall immune system. Round up your session with a
                luxurious Relaxing steam bath facilities in our premise.
              </Card.Header>
              <Card.Body>
                <Card.Title>BDT 1,000/- +vat </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PackageGallery