import React from 'react'
import { Container, Card, Row, Col } from "react-bootstrap";

const Benefit = () => {
  return (
    <Container>
      <Card varient="primary" className="my-3">
        <Card.Header as="h4" className="mt-3" style={{ color: "#7ecc10" }}>
          Benefits of Traditional Thai Massage or Sauna
        </Card.Header>
        <Card.Body>
            <Card.Text style={{ textAlign: "justify" }}>
              All this has been part of daily life here for centuries. Before
              the arrival of modern medicine, herb-alists filled the role of
              doctor and pharmacist. Monks, since Bluddhist temples were the
              center of learning also of worldly matters such as medicine. Thai
              traditional medicine holds that the body has four elements. wind,
              water, earth and fire, and ill health results from an imbalance
              between them. To remedy an ailment, the village "doctor" would
              onto the skin, or add to a steam compress. Another major component
              of traditional medicine is energy. When the energy lines are
              blocked, the indivdual will become ill, physically or emotionally.
              A traditonal Thai massage or sauna world be prescribed.
              Traditional Thai massage is a proven physical therapy that dates
              back to ancient India some time before the lifetime of Buddha.
            </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Benefit