import './App.css'
import Benefit from './Components/Benefit'
import Contact from './Components/Contact'
import Footer from './Components/Footer'
import Header from './Components/Header'
import Navbar from './Components/Navbar'
import PackageGallery from './Components/PackageGallery'
import Packages from './Components/Packages'
import QuickLinks from './Components/QuickLinks'
import SaloonSpa from './Components/SaloonSpa'
import Services from './Components/Services'
import ScrollToTop from "react-scroll-to-top";

function App() {

  return (
    <div className="App">
      <Header />
      <Navbar />
      <Benefit />
      <Services />
      <Contact />
      <PackageGallery />
      <Packages />
      <SaloonSpa />
      <QuickLinks />
      <Footer />

      <ScrollToTop smooth color="#7ecc10" style={{ background: "0" }} />
    </div>
  );

}

export default App
