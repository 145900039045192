import React from 'react'
import { Container,Card,Row,Col } from 'react-bootstrap'

const Packages = () => {
  return (
    <Container id='packages'>
      <h4 className="py-2 pt-5" style={{ color: "#7ecc10" }}>
        Organic Special Packages
      </h4>
      <Row className="d-flex justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <Card className="mb-3 mt-2" style={{ height: "220px" }}>
            <Card.Header style={{ color: "#7ecc10" }}>
              Harmony 60 minutes 1k
            </Card.Header>
            <Card.Body>
              <Card.Text>~ Body Scrub (Choice of Body Scrub)</Card.Text>
              <Card.Text>~ Rose Facial (60 Minutes)</Card.Text>
              <Card.Title>BDT 3,700/- +vat </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Card className="mb-3 mt-2" style={{ height: "220px" }}>
            <Card.Header style={{ color: "#7ecc10" }}>
              Universal 45 Minutes 1k
            </Card.Header>
            <Card.Body>
              <Card.Text>~ Body Scrub (Choice of Body Scrub)</Card.Text>
              <Card.Text>
                ~ Aromatherapy or Swedish Massage (45 Minutes)
              </Card.Text>
              <Card.Title>BDT 5,000/- +vat </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="mb-3 mt-2" style={{ height: "220px" }}>
            <Card.Header style={{ color: "#7ecc10" }}>
              Organic Special 60 Minutes TIC
            </Card.Header>
            <Card.Body>
              <Card.Text>~ Thai Massage (60 Minutes)</Card.Text>
              <Card.Text>~ Golden Facial (60 Minutes)</Card.Text>
              <Card.Text>~ Pedicure or Manicure (60 Minutes)</Card.Text>
              <Card.Title>BDT 5,000/- +vat </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="mb-3 mt-2" style={{ height: "220px" }}>
            <Card.Header style={{ color: "#7ecc10" }}>
              Strong Lovers 60 Minutes 1k
            </Card.Header>
            <Card.Body>
              <Card.Text>~ Body Scrub (Choice of Body Scrub)</Card.Text>
              <Card.Text>~ Aroma Thai Massage (90 Minutes)</Card.Text>
              <Card.Text>~ Green Mask Facial (60 Minutes)</Card.Text>
              <Card.Title>BDT 9,500/- +vat </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="mb-3 mt-2" style={{ height: "220px" }}>
            <Card.Header style={{ color: "#7ecc10" }}>
              Relaxing-i, 50 Minutes 1k
            </Card.Header>
            <Card.Body>
              <Card.Text>~ Steam Bath (30 Minutes)- 2000/-</Card.Text>
              <Card.Text>~ Jacuzzi Bath (30 Minutes)- 3000/-</Card.Text>
              <Card.Text>~ Aroma Thai Massage (60 Minutes)</Card.Text>
              <Card.Title>BDT 4,500/- +vat </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="mb-3 mt-2" style={{ height: "220px" }}>
            <Card.Header style={{ color: "#7ecc10" }}>
              Relaxing-ii, 50 Minutes 1k
            </Card.Header>
            <Card.Body>
              <Card.Text>~ Aroma Thai Massage (60 Minutes)</Card.Text>
              <Card.Text>~ Steam Bath (30 Minutes)- 3000/-</Card.Text>
              <Card.Text>~ Jacuzzi Bath (30 Minutes)- 3000/-</Card.Text>
              <Card.Title>BDT 4,500/- +vat </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Packages