import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import logo from "../img/logo.png";

const Header = () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
      >
        <Container>
          <Navbar.Brand>
            {" "}
            <a href="#">
              <img
                style={{ maxWidth: "150px", maxHeight: "100px" }}
                src={logo}
              />
            </a>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#services">Services</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
              <Nav.Link href="#packages">Packages</Nav.Link>
              <Nav.Link href="#quicklink">Quick Links</Nav.Link>
            </Nav>
            <Nav>
              <Button
                variant="success"
                style={{ padding: "0px", fontWeight: "bold" }}
              >
                <Nav.Link style={{ color: "white" }} href="#contact">
                  Contact Us
                </Nav.Link>
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
